<script setup>
import { onMounted, ref } from 'vue';

defineProps({
    modelValue: String,
    sm: Boolean,
    maxLength: Number,
});

defineEmits(['update:modelValue']);

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <input
        ref="input"
        class="border-gray-300 focus:border-teal-300 focus:ring-teal-300 rounded-lg shadow-sm-grey focus:shadow-lg-green text-gray-500 px-3.5"
        :class="[sm ? 'py-2' : 'py-2.5']"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :maxlength="maxLength"
    >
</template>
